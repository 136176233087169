/* * * * * * * * * */
/* Slider          */
/* * * * * * * * * */

function slider_home() {

  $('.slider-home').slick({
    autoplaySpeed: 3500,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    fade: false,
    autoplay: true,
    cssEase: 'linear'
  });

}

function scrollTop() {

  $(window).scroll(function () {
    scroll = $(window).scrollTop();
    if (scroll >= 100) {
      $('.main-menu').addClass('floating');
    } else {
      $('.main-menu').removeClass('floating');
    }
  });

  // Onepage

  $('.menu-list a.scroll').on('click', function (event) {
    event.preventDefault();
    $('.menu-list a.scroll').removeClass('active');
    $(this).addClass('active');
    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top
    }, 500);

  });

  // Cards

  $(window).scroll(function () {

    scroll = $(window).scrollTop();

    $('.cards').each(function (index) {
      card = $(this).offset().top;
      if (scroll >= card - 600) {
        $(this).addClass('active');
      }
    });

  });

}


/* DropZone */

function Dropzone_plugin() {

  Dropzone.options.myDropzone = {
    url: '/products/updatefile',
    paramName: "file", // The name that will be used to transfer the file
    maxFilesize: 2, // MB
    acceptedFiles: 'image/*',
    maxFiles: 1,
    dictDefaultMessage: "Agrega la imagen del producto",
    dictMaxFilesExceeded: "Solo es posible agregar una imagen por producto",
    success: function (file, response) {
      $('#path_image').val(response);
      if (file.previewElement) {
        return file.previewElement.classList.add("dz-success");
      }
    },
  }
}


function Datatables() {

  $.extend( $.fn.dataTable.defaults, {
    "language": {
      "emptyTable":     "No hay registros",
      "lengthMenu": "Se muestran _MENU_ registros por página",
      "zeroRecords": "No hay registros con el criterio de busqueda",
      "info": "Estas viendo la página _PAGE_ de _PAGES_",
      "infoEmpty": "No hay registros",
      "infoFiltered": "(filtered from _MAX_ total records)",
      "loadingRecords": "Cargando ...",
      "processing":     "Procesando ...",
      "search":         "Buscar:",
      "paginate": {
        "first":      "Primero",
        "last":       "Anterior",
        "next":       "Siguiente",
        "previous":   "Anterior"
      },
    }
  } );

}

function Forms(){

  //Active validate Form
  if($('#form_product').length){
    $('#form_product').validator();
  }

  var element = '';

  $('.delete_product').click(function (event) {
    var id = $(this).attr('id_product');
    $('.button_delete_product').attr('id_product',id);
    element = $(this).parents('tr');
  });

  $('.button_delete_product').click(function () {
    var delete_id = $(this).attr('id_product');
    console.log(delete_id);
    deleteProduct(delete_id,element)
    $('#Modal').modal('hide');

  });

  /* Edit Form */
  if($('#type_db').length > 0){
    var value = $( "#type_db" ).val();
    $( "#type" ).val(value);
    $('.marco,.puerta').hide();
    console.log(value);
    if(value === 'puerta'){
      $('.puerta').show();
      if($('#category_db')){
        var category_value = $( "#category_db" ).val();
        category_value = replaceall(category_value,' ','_');
        $( "#category" ).val(category_value);
      }
    }else{
      $( "#category" ).val('ninguna'); //Valor null
      $('.marco').show();
    }


  }

  $('#type').change(function (event) {
    $('.marco,.puerta').hide();
    if( $(this).val() === 'puerta'){
      $('.puerta').show();
    }
    $('.marco').show();
    $( "#category" ).val('ninguna'); //Valor null
  });





  /*Delete Image */
  $('.delete-image-dropzone').click(function () {
    var value = $(this).attr('id_img');
    var element = $(this);
    Delete_Img(value,element);
  });



  function deleteProduct(id,element){

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    var request = $.ajax({
      url: "/products/deleteproduct",
      method: "POST",
      data: {
        id : id,
        _token: $('meta[name="csrf-token"]').attr('content')
      },
    });

    request.done(function( msg ) {
      element.remove();
    });

    request.fail(function( jqXHR, textStatus ) {
      alert( "Request failed: " + textStatus );
    });

  }


  function Delete_Img(name_path,element) {



    var request = $.ajax({
      url: "/products/deletefile",
      method: "POST",
      data: {
        name : name_path,
        _token: $('#_token').val()
      },
    });

    request.done(function( msg ) {
      element.parents('.dz-preview').remove();
    });

    request.fail(function( jqXHR, textStatus ) {
      alert( "Request failed: " + textStatus );
    });


  }






  function replaceall(str,replace,with_this)
  {
    var str_hasil ="";
    var temp;

    for(var i=0;i<str.length;i++) // not need to be equal. it causes the last change: undefined..
    {
      if (str[i] == replace)
      {
        temp = with_this;
      }
      else
      {
        temp = str[i];
      }

      str_hasil += temp;
    }

    return str_hasil;
  }

}

//-- Initialize functions
//---------------------------------------------------
function docReady() {
  scrollTop();
  Dropzone_plugin();
  Datatables();
  Forms();
}

function winLoad() {

}

function always() {
  content();
  fixSpace();

}
//-- Do not change!!!
//---------------------------------------------------
//---------------------------------------------------
//---------------------------------------------------
$(document).ready(function () {
  always();
  window.addEventListener("resize", always);
  docReady();
});
$(window).load(function () {
  always();
  window.addEventListener("resize", always);
  winLoad();
});
//---------------------------------------------------
//---------------------------------------------------
